
    import { defineComponent } from 'vue';
    import { useRouter } from 'vue-router';
    import {
        IonCard,
        IonCardHeader,
        IonCardTitle,
        IonCardContent,
        IonIcon,
    } from '@ionic/vue';
    import { chevronForwardOutline } from 'ionicons/icons';
  export default defineComponent({
      name: 'HomeAboutMe',
      components: {
          IonCard,
          IonCardHeader,
          IonCardTitle,
          IonCardContent,
          IonIcon,
      },
      setup() {
      const router = useRouter();
      return { 
        router,
        chevronForwardOutline
      };
    },
  });
