
  import HomeMe from './HomHeaderSub.vue';
  import HomeAboutMe from './HomeAboutMe.vue';
  // import HomeSoftwareProjectsComponent from './HomeSoftwareProjectsComponent.vue';
  import HomeDiovoid from './HomeDiovoid.vue';
  import HomePGMofTexas from './HomePGMofTexas.vue';
  import HomeTrammellTech from './HomeTrammellTech.vue';

export default {
    name: 'HomeSections',
    components: {
      HomeMe,
      HomeAboutMe,
      // HomeSoftwareProjectsComponent,
      HomeDiovoid,
      HomePGMofTexas,
      HomeTrammellTech,
    }
}
