import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'
import MeKyle from '../views/MeKyle.vue';
import AboutMe from '../views/AboutMe.vue';
import SoftwareProjects from '../views/SoftwareProjects.vue';
import DioVoid from '../views/DioVoid.vue';
import MathemaView from '../views/MathemaView.vue';
import PGMofTexasView from '../views/PGMofTexasView.vue';
import TrammellTech from '../views/TrammellTech.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/aboutme',
    name: 'About Me',
    component: AboutMe
  },
  {
    path: '/diovoid',
    name: 'Diovoid LLC',
    component: DioVoid
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/kyletrammell',
    name: 'Kyle Trammell',
    component: MeKyle
  },
  {
    path: '/softwareprojects',
    name: 'Software Projects',
    component: SoftwareProjects
  },
  {
    path: '/mathema',
    name: 'Mathema (open source)',
    component: MathemaView,
  },
  {
    path: '/pgmoftexas',
    name: 'PGM of Texas LLC',
    component: PGMofTexasView,
  },
  {
    path: '/trammelltech',
    name: 'Trammell Tech LLC',
    component: TrammellTech
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
