import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!

  return (_openBlock(), _createBlock(_component_ion_buttons, { slot: "end" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goHome())),
        class: "apply-dark-mode"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            slot: "icon-only",
            icon: _ctx.homeOutline
          }, null, 8, ["icon"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}