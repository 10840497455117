
   import {
        IonButton,
        IonIcon,
        IonModal,
        IonContent,
        IonItem,
        IonText,
        IonToggle,
    } from '@ionic/vue';
    import { bulbOutline } from 'ionicons/icons';
    import { defineComponent } from 'vue';
    import storageService from '@/services/storage-service.vue';
    import { v4 as uuidv4 } from 'uuid';
    import EventBus from '@/services/eventsBus';

  export default defineComponent({
      name: 'HomeToggle',
      components: {
        IonButton,
        IonIcon,
        IonModal,
        IonContent,
        IonItem,
        IonText,
        IonToggle,
    },
    data () {
      return {
        id: null
      }
    }, 
    mounted() {
      this.id = uuidv4();
    },
    methods: {

      openThemeModal() {
        this.checkDarkMode();
        this.$refs.id.$el.present();
      },

      async checkDarkMode() {
        const theme = await storageService?.methods?.getItem('darkMode');
        if (theme == 'dark') {
          document.body.classList.toggle(theme, true);
          this.isDarkMode = true;
        }
        if (theme == 'light') {
          document.body.classList.toggle(theme, true);
          this.isDarkMode = false;
        }
      },
      toggleDarkTheme(event: any) {
        EventBus.$emit('theme-toggle', event);
      }
    },
    setup() {
      let isDarkMode = false;
    return { 
      bulbOutline, 
      isDarkMode,
     };
    }
});
