
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import storageService from '@/services/storage-service.vue';
import EventBus from '@/services/eventsBus';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
  },
  async mounted() {
    this.checkDarkMode();
    EventBus.$on("theme-toggle", (ev) => {
      this.toggleDarkTheme(ev);
    });
  },
  methods: {
    async checkDarkMode() {
      const theme = await storageService?.methods?.getItem('darkMode');
      if (!theme) {
        document.body.classList.toggle('dark', true);
        storageService?.methods?.setItem('darkMode', 'dark');
      }
      if (theme == 'dark') {
        document.body.classList.toggle(theme, true);
      }
      if (theme == 'light') {
        document.body.classList.toggle(theme, true);
      }
    },
    toggleDarkTheme(event: any) {
        document.body.classList.toggle('dark', event.detail.checked);

        if (event.detail.checked) {
          storageService?.methods?.setItem('darkMode', 'dark');
          this.isDarkMode = true;
        }
        if (!event.detail.checked) {
          storageService?.methods?.setItem('darkMode', 'light');
          this.isDarkMode = false;
        }
      }
  },
  setup() {
    let isDarkMode = false;
  return {
    isDarkMode,
  };
}
});
