
  import { defineComponent } from 'vue';
  import { useRouter } from 'vue-router';
  import { 
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonThumbnail,
    IonList,
    IonItem,
    IonLabel,
    } from '@ionic/vue';
  import { 
    logoLinkedin, 
    logoGithub,
    } from 'ionicons/icons';
export default defineComponent({
    name: 'HomeMe',
    components: {
        IonCard,
        IonCardHeader,
        IonCardTitle,
        IonCardContent,
        IonThumbnail,
        IonList,
        IonItem,
        IonLabel,
      },
      setup() {
      const router = useRouter();
      return { 
        router,
        logoLinkedin,
        logoGithub,
      };
    },
  });
