
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar,
 } from '@ionic/vue';
import { defineComponent } from 'vue';
import HomeComponent from '../components/home/HomeComponent.vue';
import HomeHeader from '../components/home/HomeHeader.vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar, 
    HomeComponent,
    HomeHeader,
  },
});
