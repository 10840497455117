
   import { 
      IonCard,
      IonCardTitle,
      IonCardContent,
      IonAccordion, 
      IonAccordionGroup,
      IonItem,
      IonList,
      IonLabel,
  } from '@ionic/vue';
  import { 
    logoLinkedin, 
    logoGithub,
    logoTwitter,
    book,
    } from 'ionicons/icons';
  import VueApexCharts from "vue3-apexcharts";
  export default {
      name: 'AboutMeComponent',
      components: {
        IonCard,
        IonCardTitle,
        IonCardContent,
        IonAccordion, 
        IonAccordionGroup,
        IonItem,
        IonList,
        IonLabel,
        apexchart: VueApexCharts,
    },
    data: function() {
      return {
        logoLinkedin,
        logoGithub,
        logoTwitter,
        book,

        mySkillsChartOptions: {
          chart: {
            id: "mySkills-Chart",
            toolbar: {
            show: true,
            tools: {
              download: false
            }
          }
          },
          plotOptions: {
            bar: {
              borderRadius: 6,
              horizontal: true,
              barHeight: '90%',
              
              dataLabels: {
                position: 'bottom'
              },
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#fff'],
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex]
          },
          offsetX: 0,
          dropShadow: {
            enabled: false
          },
        },
        stroke: {
          width: 1,
          colors: ['transparent']
        },
        legend: {
            show: false,
        },
        xaxis: {
          categories: [
            'Angular',
            'TypeScript',
            'Node.js',
            'Vue.js',
            'React.js', 
            'ASP.NET',
            'C#',
            'SQL',
            'Python', 
            'C++',
            'Java',
            'Swift'
          ],
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        tooltip: {
          enabled: false,
          x: {
              show: false
            },
          y: {
            title: {
              formatter: function () {
                return ''
              }
            }
          },
        },
        grid: {
          show: true,
          borderColor: 'tranparent',
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
              lines: {
                  show: false
              }
          },   
          yaxis: {
              lines: {
                  show: false
              }
          },  
          row: {
              colors: undefined,
              opacity: 0.5,
          },  
          column: {
              colors: undefined,
              opacity: 0.5
          },  
          padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: -10
          },  
        },
      },
      mySkillsChartseries: [
        {
          name: "mySkillsChartseries-1",
          data: [
            95,
            90,
            90, 
            80, 
            60, 
            55, 
            50, 
            70,
            65,
            40,
            30,
            30,
          ],
        },
      ],

        myToolsChartOptions: {
          chart: {
            id: "myTools-Chart",
            toolbar: {
            show: true,
            tools: {
              download: false
            }
          }
          },
          plotOptions: {
            bar: {
              borderRadius: 6,
              horizontal: true,
              barHeight: '90%',
              
              dataLabels: {
                position: 'bottom'
              },
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#fff'],
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex]
          },
          offsetX: 0,
          dropShadow: {
            enabled: false
          },
        },
        stroke: {
          width: 1,
          colors: ['transparent']
        },
        legend: {
            show: false,
        },
        xaxis: {
          categories: [
            'Github CLI',
            'Azure',
            'VS',
            'VS Code',
            'Windows Servers', 
            'Linux Servers',
            'Swagger',
            'Ngix',
            'Firebase',
            'Azure DevOps',
            'SQL Manager',
            'GCE', 
            'AWS',
          ],
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        tooltip: {
          enabled: false,
          x: {
              show: false
            },
          y: {
            title: {
              formatter: function () {
                return ''
              }
            }
          },
        },
        grid: {
          show: true,
          borderColor: 'tranparent',
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
              lines: {
                  show: false
              }
          },   
          yaxis: {
              lines: {
                  show: false
              }
          },  
          row: {
              colors: undefined,
              opacity: 0.5,
          },  
          column: {
              colors: undefined,
              opacity: 0.5
          },  
          padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: -10
          },  
      },
        },
        myToolsChartseries: [
          {
            name: "myToolsChartseries-1",
            data: [
              95,
              90, 
              85, 
              90, 
              90, 
              95, 
              90,
              80,
              95,
              40,
              80,
              50,
              50
            ],
          },
        ],
      };
    },
  }
  