import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeMe = _resolveComponent("HomeMe")!
  const _component_HomeAboutMe = _resolveComponent("HomeAboutMe")!
  const _component_HomeTrammellTech = _resolveComponent("HomeTrammellTech")!
  const _component_HomeDiovoid = _resolveComponent("HomeDiovoid")!
  const _component_HomePGMofTexas = _resolveComponent("HomePGMofTexas")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HomeMe),
    _createVNode(_component_HomeAboutMe),
    _createVNode(_component_HomeTrammellTech),
    _createVNode(_component_HomeDiovoid),
    _createVNode(_component_HomePGMofTexas)
  ], 64))
}