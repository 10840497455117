
        import { defineComponent } from 'vue';
        import {
            IonPage,
            IonHeader,
            IonToolbar,
            IonTitle,
            IonContent,
            IonButtons,
            IonBackButton,
        } from '@ionic/vue';
        import HomeToggle from '../components/other/themetoggle/ThemeToggle.vue';
        import HomeButton from '../components/home/HomeButton.vue';
        import AboutMeComponent from '../components/aboutme/AboutMeComponent.vue';

    export default defineComponent({
        name: 'AboutMe',
        components: {
            IonPage,
            IonHeader,
            IonToolbar,
            IonTitle,
            IonContent,
            IonButtons,
            IonBackButton,
            HomeButton,
            HomeToggle,
            AboutMeComponent,
        },
    });
