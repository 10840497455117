
    import { defineComponent } from 'vue';
    import { useRouter } from 'vue-router';
    import {
      IonCard,
      IonCardHeader,
      IonCardSubtitle,
      IonCardTitle,
      IonCardContent,
      IonIcon,
    } from '@ionic/vue';
    import { chevronForwardOutline } from 'ionicons/icons';
  export default defineComponent({
    name: 'PGMofTexas',
    components: {
        IonCard,
        IonCardHeader,
        IonCardSubtitle,
        IonCardTitle,
        IonCardContent,
        IonIcon,
      },
      setup() {
      const router = useRouter();
      const goToSite = () => {
        window.location.href = 'https://pgmoftexas.com';
      };
      return {
        router,
        chevronForwardOutline,
        goToSite,
      };
    },
  });
