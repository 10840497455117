
  export default {
      name: 'StorageService',
  methods: {
      getItem(item: any) {
       return localStorage.getItem(item);
      },
      setItem(item: any, val: any) {
        localStorage.setItem(item, val);
      }
    },
};
