
import {
    IonButtons,
    IonButton,
    IonIcon,
    } from '@ionic/vue';
    import { homeOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import router from '@/router';

export default defineComponent({
    name: 'HomeButton',
    components: {
    IonButtons,
    IonButton,
    IonIcon,
},
methods: {
    async goHome() {
        router.replace({ path: '/home' })
    }
},
setup() {
return { homeOutline };
}
});
