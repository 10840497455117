
  import {
    IonHeader,
    IonTitle, 
    IonToolbar,
    IonLabel,
   } from '@ionic/vue';
  import { defineComponent } from 'vue';
  import HomeToggle from '../other/themetoggle/ThemeToggle.vue';
  import HomeButton from './HomeButton.vue';

  export default defineComponent({
    name: 'HomeHeader',
    components: {
      IonHeader,
      IonTitle,
      IonToolbar,
      IonLabel,
      HomeButton,
      HomeToggle,
  }
  });
