
     import {
        IonCard,
        IonCardTitle,
        IonCardContent,
    } from '@ionic/vue';
    import { useRouter } from 'vue-router';
    import { chevronForwardOutline } from 'ionicons/icons';
    export default {
        name: 'SoftwareProjectsComponent',
        components: {
          IonCard,
          IonCardTitle,
          IonCardContent,
      },
      setup() {
      const router = useRouter();
      const goToSite = (site: string) => {
        window.location.href = site;
      };
      return {
        router,
        chevronForwardOutline,
        goToSite,
      };
    },
    }
    