
        import { defineComponent } from 'vue';
        import {
            IonPage,
            IonHeader,
            IonToolbar,
            IonTitle,
            IonContent,
            IonButtons,
            IonBackButton,
        } from '@ionic/vue';
        import HomeToggle from '../components/other/themetoggle/ThemeToggle.vue';
        import HomeButton from '../components/home/HomeButton.vue';
    export default defineComponent({
        name: 'DioVoid',
        components: {
            IonPage,
            IonHeader,
            IonToolbar,
            IonTitle,
            IonContent,
            IonButtons,
            IonBackButton,
            HomeButton,
            HomeToggle,
        },
    });
    